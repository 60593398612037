document.addEventListener('DOMContentLoaded', () => {
    bubble();
})
const bubble = () => {
    document.addEventListener('click', e => {
        if (e.target.classList.contains('card__arrow')) {
            e.preventDefault();
            const card = e.target.closest('.js-news-section-card');
            const wasExpanded = card.classList.contains('expanded');
            for (const otherCard of document.querySelectorAll('.js-news-section-card.expanded')) {
                otherCard.classList.remove('expanded');
            }
            if (!wasExpanded) {
                card.classList.add('expanded');
            }
        }
    })
}


